import { useState } from 'react'
import { devlog } from '../utils'
import useUserInfo from './useUserInfo'
import { reformatFromDB } from '../utils/formatForDb'
import { useSetAtom, useAtom } from 'jotai'
import { userTokenAtom, userTypeAtom } from '../atoms'
import useQueryUser from './useQueryUser'
const workerUrl = process.env.NEXT_PUBLIC_WORKER

interface UseResumeAuthReturn {
  isFetching: boolean
  resumeApplication: (auth_code: string) => Promise<boolean>
  requestMagicLink: (email?: string) => Promise<boolean>
  userType: UserType
}

const magicLinkRequestUrl = `${workerUrl}/magic_link`
const getExchangeUrl = (auth_code: string) => `${magicLinkRequestUrl}/${auth_code}`

const useResumeAuth = (): UseResumeAuthReturn => {
  const [isFetching, setIsFetching] = useState(false)
  const [userType, setUserType] = useAtom(userTypeAtom)
  const [userToken, setToken] = useAtom(userTokenAtom)
  const { getUser } = useQueryUser()
  const [, setUserInfo] = useUserInfo()

  const resumeApplication: UseResumeAuthReturn['resumeApplication'] = async (auth_code) => {
    setIsFetching(true)
    try {
      const response = await fetch(getExchangeUrl(auth_code))
      const data = await response.json()
      devlog('resumeApplication >> data', data)
      const { token = null, message = null, applicant_id, userTypes } = data
      if (!!token) {
        devlog('resumeApplication >> setToken', token)
        setToken(token)

        if (userTypes.length === 1 && userTypes.includes('affiliate')) {
          setUserType('affiliate')
        } else if (applicant_id && userTypes.includes('applicant')) {
          devlog('resumeApplication >> token retrieved', token)
          const { success, error, userInfo } = await getUser(token)
          if (success) {
            setUserInfo(userInfo)
            if (userTypes.includes('affiliate')) {
              setUserType('both')
            } else {
              setUserType('applicant')
            }
            setIsFetching(false)
            return true
          } else {
            console.error('resumeApplication >> Invalid response retreiving user Info', { error })
          }
        } else {
          console.error(`resumeApplication >> ${(data.message ?? 'Invalid response', { data: JSON.stringify(data) })}`)
        }
      }
    } catch (e) {
      console.error('resumeApplication >> err', e)
    }
    setIsFetching(false)
    return false
  }

  const requestMagicLink: UseResumeAuthReturn['requestMagicLink'] = async (email?: string) => {
    setIsFetching(true)
    try {
      const response = email
        ? await fetch(magicLinkRequestUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, tenant: 'setccredit' }),
          })
        : await fetch(magicLinkRequestUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({}),
          })

      const data = await response.json()
      devlog('requestMagicLink >> data', data)
      if (response.ok) {
        devlog('requestMagicLink >> response.ok')
        setIsFetching(false)
        return true
      }
      devlog('requestMagicLink >> response not ok', response)
    } catch (e) {
      console.error('requestMagicLink >> err', e)
    }
    setIsFetching(false)
    return false
  }
  return { resumeApplication, isFetching, requestMagicLink, userType }
}

export default useResumeAuth
