import dayjs from 'dayjs'
import { devlog } from '.'

export type EstimatedCredits = {
  estimate2020: number
  estimate2021: number
  totalChild2020: number
  totalChild2021: number
  totalPersonal2020: number
  totalPersonal2021: number
  totalOther2020: number
  totalOther2021: number
  unusedDays: {
    2020: {
      PERSONAL: number
      CHILD: number
    }
    2021: {
      PERSONAL: number
      CHILD: number
    }
  }
}

const scrubValue = (val?: number) => (!val || isNaN(val) ? 0 : parseInt(`${val}`))
export const creditCalculator = (userInfo: UserInfo): EstimatedCredits => {
  const netIncome2019 = scrubValue(userInfo.netIncome2019)
  const netIncome2020 = scrubValue(userInfo.netIncome2020)
  const netIncome2021 = scrubValue(userInfo.netIncome2021)

  let days2020 = { CHILD: 0, OTHER: 0, PERSONAL: 0 }
  let carryoverDays = { CHILD: 0, PERSONAL: 0, OTHER: 0 }
  let days2021 = { CHILD: 0, OTHER: 0, PERSONAL: 0 }

  const assignDays = (range: CovidDateRange) => {
    let count2020 = 0,
      countCarryover = 0,
      count2021 = 0
    if (range.end.includes('2021')) {
      const end = dayjs(range.end)
      if (range.start.includes('2020')) {
        countCarryover += end.diff('2020-12-31', 'days')
        count2020 += range.days - countCarryover
      } else if (end.isBefore('2021-04-01')) {
        countCarryover += range.days
      } else {
        count2021 += range.days
      }
    } else {
      count2020 += range.days
    }

    if (range.reason === 'CHILD') {
      let personalOther2020Used = days2020.PERSONAL + days2020.OTHER + carryoverDays.PERSONAL + carryoverDays.OTHER
      let available2020 = Math.min(10 - personalOther2020Used, count2020 + countCarryover)
      let used2020 = Math.min(available2020, count2020)
      let usedCarryover = Math.min(available2020 - used2020, countCarryover)
      days2020.OTHER += used2020
      carryoverDays.OTHER += usedCarryover
      days2020.CHILD += count2020 - used2020
      carryoverDays.CHILD += countCarryover - usedCarryover

      let personalOther2021Used = days2021.PERSONAL + days2021.OTHER
      let available2021 = Math.min(10 - personalOther2021Used, count2021)
      let used2021 = Math.min(available2021, count2021)
      days2021.OTHER += used2021
      days2021.CHILD += count2021 - used2021
    } else {
      days2020[range.reason as keyof typeof days2020] += count2020
      carryoverDays[range.reason as keyof typeof days2020] += countCarryover
      days2021[range.reason as keyof typeof days2020] += count2021
    }
  }

  //track personal and other days
  userInfo.covidDates2020?.filter((range) => ['PERSONAL', 'OTHER'].includes(range.reason)).forEach(assignDays)
  userInfo.covidDates2021?.filter((range) => ['PERSONAL', 'OTHER'].includes(range.reason)).forEach(assignDays)
  userInfo.covidDates2020?.filter((range) => range.reason === 'CHILD').forEach(assignDays)
  userInfo.covidDates2021?.filter((range) => range.reason === 'CHILD').forEach(assignDays)

  let unusedDays = {
    2020: {
      PERSONAL: 10 - days2020['PERSONAL'] - days2020['OTHER'] - carryoverDays['PERSONAL'] - carryoverDays['OTHER'],
      CHILD: 50 - days2020['CHILD'] - carryoverDays['CHILD'],
    },
    2021: { PERSONAL: 10 - days2021['PERSONAL'] - days2021['OTHER'], CHILD: 60 - days2021['CHILD'] },
  }

  // devlog('days assigned', { days2020, carryoverDays, days2021, unusedDays })

  // 2020 credit
  //  daily rates
  const dailyRate2020Income = Math.round(Math.max(netIncome2019, netIncome2020) / 260)
  const dailyRate2020 = Math.min(dailyRate2020Income, 511)
  const dailyRateOther2020 = Math.min(Math.round(dailyRate2020Income * 0.67), 200)
  const dailyRateChild2020 = Math.min(Math.round(dailyRate2020Income * 0.67), 200)
  // devlog('2020 daily rates', {dailyRate2020, dailyRateOther2020, dailyRateChild2020})

  //  number of days by category
  const personalDays2020 = Math.min(10, days2020.PERSONAL)
  const otherDays2020 = Math.min(10 - personalDays2020, days2020.OTHER)
  const childDays2020 = Math.min(50, days2020.CHILD)

  //  credit amounts
  const totalPersonal2020 = Math.min(5110, personalDays2020 * dailyRate2020)
  const totalOther2020 = Math.min(2000, otherDays2020 * dailyRateOther2020)
  const totalChild2020 = Math.min(10000, childDays2020 * dailyRateChild2020)
  const estimate2020 = Math.floor(totalPersonal2020 + totalOther2020 + totalChild2020)

  // 2021 credit
  //  daily rates
  const dailyRate2021Income = Math.round(Math.max(netIncome2020, netIncome2021) / 260)
  const dailyRate2021 = Math.min(dailyRate2021Income, 511)
  const dailyRateOther2021 = Math.min(Math.round(dailyRate2021Income * 0.67), 200)
  const dailyRateChild2021 = Math.min(Math.round(dailyRate2021Income * 0.67), 200)
  // devlog('2021 daily rates', {dailyRate2021, dailyRateOther2021, dailyRateChild2021})

  //  Q1 Credit
  //  part 1, Q1 Personal carryover, Q1 Other carryover
  //  part 2 Q1 Child carryover
  //    Q1 Allowed Days
  const carryoverPersonalDays = Math.min(10 - (personalDays2020 + otherDays2020), carryoverDays.PERSONAL)
  const carryoverOtherDays = Math.min(
    10 - (personalDays2020 + otherDays2020 + carryoverDays.PERSONAL),
    carryoverDays.OTHER
  )
  const carryoverChildDays = Math.min(50 - childDays2020, carryoverDays.CHILD)
  //    Q1 Credits
  const carryoverPersonal2021 = carryoverPersonalDays * dailyRate2021
  const carryoverOther2021 = carryoverOtherDays * dailyRateOther2021
  const carryoverChild2021 = carryoverChildDays * dailyRateChild2021
  const totalCarryover2021 = carryoverPersonal2021 + carryoverOther2021 + carryoverChild2021

  //  Q2-Q3 Credit
  //  part 3, care for self and other
  //  part 4, family leave care for another
  //  part 5
  //    Q2 Allowed Days
  const personalDays2021 = Math.min(10, days2021.PERSONAL)
  const otherDays2021 = Math.min(10 - personalDays2021, days2021.OTHER)
  const childDays2021 = Math.min(60, days2021.CHILD)

  //    Q2-Q3 Credits
  const totalPersonal2021 = Math.min(5110, personalDays2021 * dailyRate2021)
  const totalOther2021 = Math.min(2000, otherDays2021 * dailyRateOther2021)
  const totalChild2021 = Math.min(12000, childDays2021 * dailyRateChild2021)
  const estimate2021 = Math.floor(totalPersonal2021 + totalOther2021 + totalChild2021 + totalCarryover2021)

  const calculatedValues = {
    estimate2020,
    estimate2021,
    totalChild2020,
    totalChild2021,
    totalPersonal2020,
    totalPersonal2021,
    totalOther2020,
    totalOther2021,
  }

  for (const key in calculatedValues) {
    if (isNaN(calculatedValues[key as keyof typeof calculatedValues])) {
      console.error('Calculated values includes NaN', calculatedValues)
      break
    }
  }

  // devlog('credits calculated', {
  //   userInfo,
  //   '2020': {
  //     totalPersonal2020,
  //     totalOther2020,
  //     totalChild2020,
  //   },
  //   estimate2020,
  //   '2021': {
  //     totalPersonal2021,
  //     totalOther2021,
  //     totalChild2021,
  //     carryoverChild2021,
  //     carryoverPersonal2021,
  //     carryoverOther2021,
  //   },
  //   estimate2021,
  // })
  //  credit amounts

  return { ...calculatedValues, unusedDays }
}
