'use client'
import { debounce } from '@mui/material'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  userIdAtom,
  userInfoReducerAtom,
  userTokenAtom,
  userTypeAtom,
} from '../atoms'
import { devlog } from '../utils'
import useQueryUser from './useQueryUser'

export default function useUserInfo(): [
  UserInfo,
  (val: UserInfo) => void,
  boolean,
  {
    requery: () => void
    reloginRequired: boolean
    logout: () => void
  },
] {
  const [userId, setUserId] = useAtom(userIdAtom)
  const [userToken, setUserToken] = useAtom(userTokenAtom)
  const [userType, setUserType] = useAtom(userTypeAtom)
  const [userInfo, setUserInfo] = useAtom(userInfoReducerAtom)
  const [loaded, setLoaded] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const { getUser } = useQueryUser()
  const fetchingRef = useRef(false)
  const [fetching, setFetching] = useState(false)
  const [reloginRequired, setReloginRequired] = useState(false)

  useEffect(() => {
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized) {
      if (userInfo.id || !userToken) {
        devlog('useUserInfo >> isLoaded', { 'userInfo.id': userInfo.id, userToken })
        setLoaded(true)
      }
      if (!!userToken && !fetching && !userInfo.id) {
        setFetching(true)
        fetchingRef.current = true
        debounceRequestUser()
        // requestUser()
      }
    }
  }, [initialized, userId, userToken, userInfo?.id])

  const requestUser = async () => {
    devlog('useUserInfo >> requestUser - initial')

    const { error, success, userInfo } = await getUser()
    devlog('useUserInfo >> requestUser', { error, success, userInfo })
    if (success) {
      setUserInfo(userInfo)
    } else {
      if (error && error.toLocaleLowerCase() === 'invalid token') {
        setReloginRequired(true)
      }
    }
    setFetching(false)
    fetchingRef.current = false

    return userInfo
  }

  const debounceRequestUser = useCallback(debounce(requestUser, 1000), [userToken, userId])

  const logout = () => {
    devlog('useUserInfo >> logout')
    setUserId('')
    setUserType('unknown')
    setUserToken('')
    setUserInfo({ shouldClear: true })
    setReloginRequired(false)
  }

  return [
    userInfo,
    setUserInfo,
    loaded,
    {
      requery: requestUser,
      reloginRequired,
      logout,
    },
  ]
}
