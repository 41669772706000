import { atom } from 'jotai'
import { atomWithReducer, atomWithStorage, createJSONStorage } from 'jotai/utils'
import { creditCalculator, devlog } from './utils'

export const userInfoReducerAtom = atomWithReducer<UserInfo, UserInfo & { shouldClear?: boolean }>(
  {},
  (existing, update) => {
    if (update?.shouldClear) {
      devlog('Wiping stored userInfo')
      return {}
    } else {
      devlog('Merging existing user info and update', { existing, update })
      if (!existing.id && update?.id) {
        devlog('Setting LogRocket user id to', update.id)
      }
    }

    return { ...existing, ...update }
  }
)

const storage = createJSONStorage<string>(() => ({
  getItem: (key: string) => localStorage.getItem(key),
  setItem: (key: string, newValue: string) => localStorage.setItem(key, newValue),
  removeItem: (key: string) => localStorage.removeItem(key),
}))

export const userIdAtom = atomWithStorage<string>('user_id', '', storage)
export const userTokenAtom = atomWithStorage<string>('user_token', '', storage)
export const userTypeAtom = atomWithStorage<UserType>('user_type', 'unknown')
export const boxTokenAtom = atom<string>('')
export const boxUserFolderIdAtom = atom<string>('')